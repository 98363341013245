import { createSlice } from '@reduxjs/toolkit';
// utils
import { SUCCESS } from '../../assets/data/resCode';
import axios from '../../utils/axios';
// routes

const initialState = {
  isloading: false,
  error: null,
  video: {
    marketing: '',
    tutorial: '',
  },
  gallery: [],
  totalCount: 0,
};

const slice = createSlice({
  name: 'media',
  initialState,
  reducers: {
    startLoading(state) {
      state.isloading = true;
    },
    finishLoading(state) {
      state.isloading = false;
    },

    hasError(state, action) {
      state.isloading = false;
      state.error = action.payload;
    },
  },
});

export default slice.reducer;

export const createImages = (images, SnackBar) => async (dispatch) => {
  try {
    const response = await axios.post('/media/gallery', images);
    switch (response.data.code) {
      case SUCCESS:
        SnackBar('Successfully added', 'success');
        break;
      default:
        SnackBar(response.data.message, 'error');
        break;
    }
    dispatch(slice.actions.finishLoading());
  } catch (err) {
    dispatch(slice.actions.hasError(err));
    SnackBar(err.message, 'error');
  }
};

export const deleteImage = (id, SnackBar) => async (dispatch) => {
  try {
    const response = await axios.put(`/media/gallery/${id}`);
    switch (response.data.code) {
      case SUCCESS:
        SnackBar('Successfully deleted.', 'success');
        break;
      default:
        SnackBar(response.data.message, 'error');
        break;
    }
    dispatch(slice.actions.finishLoading());
  } catch (err) {
    dispatch(slice.actions.hasError(err));
    SnackBar(err.message, 'error');
  }
};
