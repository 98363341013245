// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// components
import SvgColor from '../../../components/svg-color';

// ----------------------------------------------------------------------

const icon = (name) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
);

const ICONS = {
  add_admin: icon('ic_add_admin'),
  user: icon('ic_user'),
  fences: icon('ic_3dmodel'),
  gates: icon('ic_gate'),
  mail: icon('ic_mail'),
  folder: icon('ic_folder'),
  invoice: icon('ic_invoice'),
  blog: icon('ic_blog'),
  fence_ed: icon('ic_university'),
  media: icon('ic_media'),
  qa: icon('ic_qa'),
  banking: icon('ic_banking'),
  notification: icon('ic_notification'),
  affiliates: icon('ic_person_fill_badge_plus'),
  onboarding: icon('ic_onboarding')
};

const navConfig = [
  {
    // subheader: 'general management',
    items: [{ title: `Add Administrator`, path: PATH_DASHBOARD.addAdmin, icon: ICONS.add_admin }],
  },
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: 'general management',
    items: [
      {
        // USER
        title: 'users',
        path: PATH_DASHBOARD.general.user.list,
        icon: ICONS.user,
      },
      { title: `3D Models`, path: PATH_DASHBOARD.general.fences, icon: ICONS.fences },
      { title: `Gates`, path: PATH_DASHBOARD.general.gates.list, icon: ICONS.gates },
      { title: 'Asset Request', path: PATH_DASHBOARD.general.assetRequest.root, icon: ICONS.mail },
      {
        title: 'fileManager',
        path: PATH_DASHBOARD.general.file,
        icon: ICONS.folder,
      },
      // BILLING
      {
        title: 'billing',
        path: PATH_DASHBOARD.general.billing,
        icon: ICONS.invoice,
      },
      // BLOG
      {
        title: 'blog',
        path: PATH_DASHBOARD.general.blog.root,
        icon: ICONS.blog,
      },
      {
        title: 'RealityFence ED',
        path: PATH_DASHBOARD.general.ED.root,
        icon: ICONS.fence_ed,
      },
      {
        title: 'Media Manager',
        path: PATH_DASHBOARD.general.media.root,
        icon: ICONS.media,
      },

      {
        title: 'Q & A',
        path: PATH_DASHBOARD.general.QA.root,
        icon: ICONS.qa,
      },
      {
        title: 'Subscription',
        path: PATH_DASHBOARD.general.subscription.root,
        icon: ICONS.banking,
      },
      // {
      //   title: 'Broadcast System',
      //   path: PATH_DASHBOARD.general.broadcast.root,
      //   icon: ICONS.notification,
      // },
      {
        title: 'Affiliates',
        path: PATH_DASHBOARD.general.affiliates,
        icon: ICONS.affiliates,
      },
      {
        title: 'onboarding',
        path: PATH_DASHBOARD.general.onboarding,
        icon: ICONS.onboarding,
      }
    ],
  },
];

export default navConfig;
