const SUCCESS = 200;
const CREATED = 201;

const UNAUTHORIZED = 401;
const FORBIDDEN = 403;
const NO_EXIST = 404;
const ALREADY_EXIST = 407;
const INVALID_USER = 410;
const PASSWORD_NOT_EQUAL = 411;
const VALIDATION_ERROR = 412;
const VERIFY_CODE_INCORRECT = 413;
const CURRENT_PASSWORD_INCORRECT = 415;
const ALREAY_LOGGEDIN = 417;

const INTERNAL_SERVER_ERROR = 500;

module.exports = {
  SUCCESS,
  CREATED,
  UNAUTHORIZED,
  FORBIDDEN,
  NO_EXIST,
  INTERNAL_SERVER_ERROR,
  INVALID_USER,
  PASSWORD_NOT_EQUAL,
  ALREADY_EXIST,
  VALIDATION_ERROR,
  VERIFY_CODE_INCORRECT,
  CURRENT_PASSWORD_INCORRECT,
  ALREAY_LOGGEDIN,
};
