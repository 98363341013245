export const Messages = {
  // Auth
  USER_NOT_EXIST: 'User not exist',
  INVALID_USER: 'Incorrect username or email',
  INVALID_PASSWORD: 'You did not set password yet',
  INCORRECT_PASSWORD: 'Incorrect password',
  // Verify
  VERIFICATION_CODE_SEND_SUCCESS: 'Verification code have sent successfully',
  VERIFICATION_CODE_SEND_FAIL: 'Sending Verification Code Failed',
  VERIFY_CODE_INCORRECT: 'Verification code is incorrect',
  VERIFICATION_SUCCESS: 'Verify Success',
  // Server Error
  INTERVAL_SERVER_ERROR: 'Something Went Wrong',
};
