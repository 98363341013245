export const ApiResCode = {
  SUCCESS: 200,
  CREATED: 201,

  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
  NO_EXIST: 404,
  VALIDATION_ERROR: 406,
  ALREADY_EXIST: 407,

  INTERNAL_SERVER_ERROR: 500,
  INVALID_USER: 410,
  PASSWORD_NOT_EQUAL: 411,
  USER_NOT_VERIFIED: 412,

  VERIFY_CODE_INCORRECT: 413,
  USER_IS_BANNED: 414,

  INCORRECT_PASSWORD: 415,
  INVALID_PASSWORD: 416,

  ALREADY_LOGIN: 417,

  NOT_ACTIVE_SUBSCRIPTION: 418,

  EMAIL_SENDING_ERROR: 419,
};
